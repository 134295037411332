<template>
  <div ref="station" class="station" @click.stop="dropdata" @focus="foucus" @blur="blur" tabindex="0">
    <div style="display:flex;height:60px;overflow:hidden;">
      <div class="aha" style="width:225px">
        <span id="star">*</span>
        <span class="dropleft">{{hasStation}}</span>
      </div>
      <div style="float:right;width:120px;display: flex">
        <span class="dropright" style="width: 40px;display: block;overflow: hidden;line-height: 60px;flex: 1">{{stationcontent.product_code}}</span>
        <img class="dropimg" src="../assets/image/ic_nav_drop@3x.png" alt="">
      </div>
    </div>
    <div ref="selectItem" id="selectItem">
      <ul id="ul">
        <li id="dropli" style="cursor:pointer" v-for="item in stationData" :key="item.id" @click.stop="changeli(item)">{{item.name + '(' + item.product_code + ')'}}</li>
      </ul>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {
    //   接受父组件传过来的值
    stationData:{
      type:Array
    },
    placeholder: {
      type: String
    },
    defaultsite: {
      type: String
    }
  },
  data () {
    return {
      stationcontent: {},
      hasStation: '',
      defaultid: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    foucus () {
      this.$refs.station.style.border = '1px solid #E02020'
    },
    blur () {
      this.$refs.station.style.border = 'none'
      this.$refs.selectItem.style.display = 'none';
    },
    changeli (item) {
      this.stationcontent = item
      this.$emit('selectvalue', item)
      this.hasStation = item.name
      if(this.$refs.selectItem.style.display == 'none' || this.$refs.selectItem.style.display == ''){
          this.$refs.selectItem.style.display = 'block';
      }else{
          this.$refs.selectItem.style.display = 'none';
      }
    },
    // 站点下拉
    dropdata () {
      if(this.$refs.selectItem.style.display == 'none' || this.$refs.selectItem.style.display == ''){
          this.$refs.selectItem.style.display = 'block';
      }else{
          this.$refs.selectItem.style.display = 'none';
      }
    },
  },
  filters: {},
  mounted () {
    if (this.defaultsite) {
      if (this.stationData && this.stationData.length!==0) {
        this.stationData.forEach(item => {
          if (item.id == +this.defaultsite) {
            this.stationcontent = item
            this.hasStation = item.name
            this.$emit('selectvalue', item)
          }
        })
      }
    } else if (!this.defaultsite && this.stationData.length!==0) {
      this.stationcontent = this.stationData.length[0]
    } else {
      this.hasStation = this.placeholder
    }
  },
  created () { }
}
</script>

<style scoped>
#star{
  color: #E02020;
  margin-left: 15px;
}
/* // 下拉框样式 */
.station {
    /* // position relative */
    line-height: 60px;
    width: 100%;
    height: 60px;
    background-color: #2B323D;
    margin: 0 auto;
    border-radius: 3px;
    text-align: left;
  }
  .dropimg {
    width: 30px;
    height: 30px;
    float: right;
    margin: 15px 15px 15px 0;
  }
  .dropleft {
    font-family: Avenir;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0px;
    color: #fff;
    margin-left: 15px;
  }
  .dropright {
    font-family: Avenir;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: right;
    color: rgba(255,255,255,.5);
  }
  #selectItem{
    display: none;
    max-height: 248px;
    overflow: auto;
    background-color: #1C222D;
    z-index: 999;
    transform: translateZ(1000px);/* IOS层级问题 */
  }
  #selectItem ul li{
    height: 60px;
    width: 100%;
    line-height: 60px;
    padding-left: 15px;
    cursor: pointer;
    border-radius: 3px;
    margin-top: 2px;
    background-color: #2B323D;
  }
  #ul {
    width: 100%;
    font-family: Avenir;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
  }
</style>
