<template>
  <div class="uploadbox" ref="uploadbox">
    <div class="upload" ref="upload" v-if="hidden">
      <div>
        <img src="../assets//image//ic_nav_add.png" style="opacity:0.5" alt="">
        <input class="upfile" type="file" accept="image/*" @change="imgchange" ref="inputfile" style="display:hidden">
        <img :src="viewsrc" alt="">
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {
    viewsrc: {
      type: String
    },
    limit: {
      type: Number
    }
  },
  data () {
    return {
      file: {},
      viewurl: '',
      hidden: true,
      filenameList: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    imgchange () {
      this.file = document.getElementsByClassName('upfile')[0].files[0]
      this.filenameList.push(this.file.name)
      console.log(this.filenameList)
      let stopnum = 0
      for (let i=0;i< this.filenameList.length;i++) {
        if (this.filenameList[i] == this.file.name) {
          stopnum++
        }
        if (stopnum > 1) {
          for (let i=0;i< this.filenameList.length;i++) {
            if (this.filenameList[i] == this.file.name) {
              this.filenameList.splice(i,1)
              console.log('循环后',this.filenameList)
              return
            }
          }
          return
        }
      }
      // 给调用者传值
      this.$emit('imgfile', this.file,false)

      let _that = this
      let fr = new FileReader()
      let div = document.createElement("div")
      let img = document.createElement("img")
      div.className = 'apeenddiv'
      div.id = this.file.name
      if (document.getElementsByClassName('apeenddiv').length === this.limit) {
        return
      }
      fr.onload = function (e) {
        _that.$emit('imgsrc', e.target.result)
        _that.viewurl = e.target.result
        img.src = _that.viewurl
        img.style.width = '80px'
        img.style.height = '80px'
        img.style.marginRight = '10px'
        div.appendChild(img)
        div.addEventListener('click', function(e){
          e.preventDefault()
          for (let i=0;i< _that.filenameList.length;i++) {
            if (_that.filenameList[i] == div.id) {
              _that.filenameList.splice(i,1)
            }
          }
          console.log('删除和',_that.filenameList)
          div.parentNode.removeChild(div)
          _that.$emit('imgfile', '',div.id)
          if (document.getElementsByClassName('apeenddiv').length < +_that.limit) {
            _that.hidden = true
          }
          if (_that.$refs.inputfile) {
            console.log('vdsv',_that.$refs.inputfile)
            _that.$refs.inputfile.value = ''
          }
        })
        _that.$refs['uploadbox'].insertBefore(div, _that.$refs['upload'])
        if (document.getElementsByClassName('apeenddiv').length == +_that.limit) {
          _that.hidden = false
        }
      }
      fr.readAsDataURL(this.file)
    }
  },
  filters: {},
  mounted () {
    console.log(this.$refs)
  },
  created () { }
}
</script>

<style scoped>
.uploadbox {
  /* width: 345px; */
  margin: 0 auto;
  height: 80px;
  display: flex;
}
.upload {
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px dashed #FFFFFF80;
  border-radius: 3px
}
.upload img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upfile {
  display: block;
  width: 80px;
  height: 80px;
  opacity: 0;
}
.apeenddiv {
  width:80px;
  height:80px;
  border: 1px dashed #FFFFFF80;
}
</style>
